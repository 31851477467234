import {fetchJSONByGet, fetchJSONByPost, fetchJSONByDel} from './index.js'

// Category & Url
// 创建
export const newItemApi = (body) => fetchJSONByPost('/adms_api_v0/google/landingLink', body)
// 获取列表
export const getListApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/google/landingLink/page', params, {trigger})
// 激活/关闭
export const activeItemApi = (id, active) => fetchJSONByPost(`/adms_api_v0/google/landingLink/${id}/${active}`)
// 批量激活/关闭
export const activeItemsApi = (body, active) => fetchJSONByPost(`/adms_api_v0/google/landingLink/batch/${active}`, body)
// 更新
export const updateItemApi = (id, body) => fetchJSONByPost(`/adms_api_v0/google/landingLink/${id}`, body)
// 获取某一
export const getItemApi = (id) => fetchJSONByGet(`/adms_api_v0/google/landingLink/${id}`)
