import {fetchJSONByGet, fetchJSONByPost, fetchJSONByDel} from './index.js'
// Budget Plan
export const getPlansApi = (params, trigger=false) => fetchJSONByGet('/adms_api_v0/facebook/adjust/plan/list', params, {trigger})

export const getSyncTimeApi = (website) => fetchJSONByGet(`/adms_api_v0/facebook/budget/syncTime/${website}`)

export const getLogsApi = (id) => fetchJSONByGet(`/adms_api_v0/facebook/adjust/plan/${id}/log`)

export const getAccountsApi = (params) => fetchJSONByGet('/adms_api_v0/facebook/getAllAccountByWebsiteAndChannel', params)

export const updateBudgetApi = (body) => fetchJSONByPost('/adms_api_v0/facebook/adjust/plan/update', body)

export const confirmBudgetPlansApi = (ids) => fetchJSONByPost('/adms_api_v0/facebook/adjust/plan/confirm', ids)

export const refreshRawDataApi = (params) => fetchJSONByGet('/adms_api_v0/google/budget/refresh', params)

export const exportRawDataApi = (website, channel) => fetchJSONByPost(`/adms_api_v0/facebook/budget/${website}/csv`, {}, {params: {channel}, responseType: 'blob'})
// Budget Plan - Config
export const getConfigsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/facebook/budget/rule/page', params, {trigger})

export const newConfigApi = (body) => fetchJSONByPost('/adms_api_v0/facebook/budget/rule', body)

export const updateConfigApi = (id, body) => fetchJSONByPost(`/adms_api_v0/facebook/budget/rule/${id}`, body)

export const getConfigApi = (id) => fetchJSONByGet(`/adms_api_v0/facebook/budget/rule/${id}`)

export const delConfigApi = (id) => fetchJSONByDel(`/adms_api_v0/facebook/budget/rule/${id}`)

export const activeConfigApi = (id, active) => fetchJSONByPost(`/adms_api_v0/facebook/budget/rule/${id}/active/${active}`)

// Budget 开关记录
export const getAdSwitchHisListApi = (params, trigger=false) => fetchJSONByGet('/adms_api_v0/facebook/switch/rule/history/page', params, {trigger})
// Budget 开关规则配置
export const getAdSwtichConfigsApi = (params, trigger=false) => fetchJSONByGet('/adms_api_v0/facebook/switch/rule/page', params, {trigger})

export const activeAdSwitchConfigApi = (id, active) => fetchJSONByPost(`/adms_api_v0/facebook/switch/rule/${id}/active/${active}`)

export const delAdSwitchConfigApi = (id) => fetchJSONByDel(`/adms_api_v0/facebook/switch/rule/${id}`)

export const newAdSwitchConfigApi = (body) => fetchJSONByPost('/adms_api_v0/facebook/switch/rule', body)
export const updateAdSwitchConfigApi = (id, body) => fetchJSONByPost(`/adms_api_v0/facebook/switch/rule/${id}`, body)

