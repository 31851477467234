import {fetchJSONByGet, fetchJSONByPost, fetchJSONByDel} from './index.js'

// GG
// 创建账号
export const newItemApi = (body) => fetchJSONByPost('/adms_api_v0/account', body)
// 获取账号列表
export const getListApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/account/page', params, {trigger})
// 激活/关闭账号
export const activeItemApi = (id, active) => fetchJSONByPost(`/adms_api_v0/account/${id}/${active}`)
// 批量激活/关闭账号
export const activeItemsApi = (ids, active) => fetchJSONByPost(`/adms_api_v0/account/batch/${active}`, {ids})
// 更新
export const updateApi = (id, body) => fetchJSONByPost(`/adms_api_v0/account/${id}`, body)