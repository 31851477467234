import {fetchJSONByGet, fetchJSONByPost} from './index.js'
// 获取进阶测款列表
export const getListApi = (params, trigger=false) => fetchJSONByGet('/adms_api_v0/product/superTest/report', params, {trigger})
// 获取配置
export const getConfigApi = () => fetchJSONByGet('/adms_api_v0/product/superTest/config')
// 更新配置
export const updateConfigApi = (body) => fetchJSONByPost(`/adms_api_v0/product/superTest/config`, body)
// 添加选款
export const addSpusApi = (body) => fetchJSONByPost('/adms_api_v0/product/superTest/spus/upload', body)

export const delProductApi = (id) => fetchJSONByGet(`/adms_api_v0/product/superTest/detail/${id}/remove`)

