<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'app',
		components: {
			// 全局组件
		},
		created() {
			// 初始化用户信息
			this.$store.commit('initNavBar')
			this.$store.commit('initChannel')
			this.$store.commit('initContent')
			this.$store.commit('initUser')
			this.$store.dispatch('download-manager/runLoop')
		},
		data() {
			return {}
		},
		mounted() {},
		// 依赖注入
		provide() {
			return {
				app: this
			}
		}
	}
</script>
