import {fetchJSONByGet, fetchJSONByPost} from './index.js'
// 获取策略配置列表
export const getListApi = (params, trigger=false) => fetchJSONByGet('/adms_api_v0/facebook/automation/advertise/page', params, {trigger})
// 创建策略配置
export const newAdStrategyConfigApi = (body) => fetchJSONByPost('/adms_api_v0/facebook/automation/advertise', body)
// 更新策略配置
export const updateAdStrategyConfigApi = (id, body) => fetchJSONByPost(`/adms_api_v0/facebook/automation/advertise/${id}`, body)
// 开启/关闭策略配置
export const activeAdStrategyConfigApi = (id, active) => fetchJSONByPost(`/adms_api_v0/facebook/automation/advertise/${id}/active/${active}`)
// 获取策略配置变更历史记录
export const getLogsApi = (id) => fetchJSONByGet(`/adms_api_v0/facebook/automation/advertise/log/${id}/list`)
// 获取策略生效记录
export const getRecordsApi = (params, trigger=false) => fetchJSONByGet('/adms_api_v0/facebook/automation/advertise/auto/log', params, {trigger})
export const getDetailsApi = (id) => fetchJSONByGet(`/adms_api_v0/facebook/automation/advertise/${id}/groups`)
export const remakePicsApi = (id) => fetchJSONByGet(`/adms_api_v0/facebook/automation/advertise/pics/${id}/remake`)
