import {fetchJSONByGet, fetchJSONByPost, fetchJSONByDel} from './index.js'

// Display UDA 图片模板
// 添加图片模板
export const newItemApi = (body) => fetchJSONByPost('/adms_api_v0/google/display_uda', body)
// 更新图片模板
export const updateItemApi = (id, body) => fetchJSONByPost(`/adms_api_v0/google/display_uda/${id}`, body)
// 获取图片模板列表
export const getListApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/google/display_uda/page', params, {trigger})
// 删除某一模板
export const delItemApi = (id) => fetchJSONByDel(`/adms_api_v0/google/display_uda/${id}`)
// 批量删除
export const delItemsApi = (body) => fetchJSONByPost(`/adms_api_v0/google/display_uda/delete`, body)
// 获取某一图片模板
export const getItemApi = (id) => fetchJSONByGet(`/adms_api_v0/google/display_uda/${id}`)
