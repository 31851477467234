import {fetchJSONByGet, fetchJSONByPost} from './index.js'

// 获取所有站点
export const fetchChannelsApi = (parent=false) => fetchJSONByGet('/adms_api_v0/product/channel',{parent}, {trigger: false})
// 获取列表
export const fetchProductsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/product/page', params, {trigger})
// 预览
export const previewAdsApi = (body, single = false) => fetchJSONByPost(`/adms_api_v0/product/facebook${single ? '/single' : ''}/preview`, body)
// 发布广告
export const publishAdsApi = (id, body) => fetchJSONByPost(`/adms_api_v0/product/facebook/confirm/${id}`, body)
// 获取分类
export const fetchCategoriesAPi = (params, signal = null) => fetchJSONByGet('/adms_api_v0/product/categories', params, {signal})

// 获取所有作图模版
export const fetchProductTemplatesApi = () => fetchJSONByGet('/adms_api_v0/product/templates')
// 获取单图列表
export const fetchSingleProductsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/product/single/page', params, {trigger})

// 重复选款列表
export const fetchBlockProductsApi = (params) => fetchJSONByGet('/adms_api_v0/product/block/page', params)
// 解禁重复选款
export const delBlockProductsApi = (body) => fetchJSONByPost('/adms_api_v0/product/block/remove', body)
// 去重列表
export const fetchDistinctProductsApi = (params, trigger) => fetchJSONByGet('/adms_api_v0/product/page/distinct', params, {trigger})
// 生成单图
export const covertCarouselToSingleApi = (body) => fetchJSONByPost('/adms_api_v0/product/facebook/convertCarouselToSingle', body)
// 获取所有站点
export const fetchAllChannelsApi = () => fetchJSONByGet('/adms_api_v0/channel/all', {}, {trigger: false})

export const uploadToShopifyApi = (body) => fetchJSONByPost('/adms_api_v0/product/uploadToShopify', body)


// 更新图片
export const updateProductImgApi = (id,imageLink) => fetchJSONByPost(`/adms_api_v0/product/${id}/image/upload`, {}, {params: {imageLink}})
export const updateSingleProductImgApi = (id,imageLink) => fetchJSONByPost(`/adms_api_v0/product/single/${id}/image/upload`, {}, {params: {imageLink}})