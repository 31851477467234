import {fetchJSONByGet, fetchJSONByPost, fetchJSONByDel} from './index.js'

// 获取国家列表
export const getCountriesApi = () => fetchJSONByGet('/adms_api_v0/countries/OWK', {original: true})
// 获取所有语言全称
export const getFullLanguagesApi = () => fetchJSONByGet('/adms_api_v0/languages/full')
// 获取所有语言code
export const getShortLanguagesApi = () => fetchJSONByGet('/adms_api_v0/languages/short')

// Context
// 创建文案
export const createContextApi = (body) => fetchJSONByPost('/adms_api_v0/content', body)
// 获取文案列表
export const fetchContextsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/content/page', params, {trigger})
// 激活/关闭文案
export const updateContextApi = (id, active) => fetchJSONByPost(`/adms_api_v0/content/${id}/${active}`)
// 批量激活/关闭文案
export const batchUpdateContextApi = (body, active) => fetchJSONByPost(`/adms_api_v0/content/batch/${active}`, body)
// 更新
export const updateContextDetailApi = (id, body) => fetchJSONByPost(`/adms_api_v0/content/${id}`, body)
// 获取某一文案
export const getContextApi = (id) => fetchJSONByGet(`/adms_api_v0/content/${id}`)

// Page & Pixel
// 创建
export const createPagePixelApi = (body) => fetchJSONByPost('/adms_api_v0/pixel', body)
// 获取列表
export const fetchPagePixelsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/pixel/page', params, {trigger})
// 激活/关闭
export const updatePagePixelApi = (id, active) => fetchJSONByPost(`/adms_api_v0/pixel/page/${id}/${active}`)
// 批量激活/关闭
export const batchUpdatePagePixelApi = (body, active) => fetchJSONByPost(`/adms_api_v0/pixel/batch/${active}`, body)
// 更新
export const updatePagePixelDetailApi = (id, body) => fetchJSONByPost(`/adms_api_v0/pixel/${id}`, body)
// 获取某一
export const getPagePixelApi = (id) => fetchJSONByGet(`/adms_api_v0/pixel/${id}`)

// Category & Url
// 创建
export const createCategoryUrlApi = (body) => fetchJSONByPost('/adms_api_v0/landingLink', body)
// 获取列表
export const fetchCategoryUrlsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/landingLink/page', params, {trigger})
// 激活/关闭
export const updateCategoryUrlApi = (id, active) => fetchJSONByPost(`/adms_api_v0/landingLink/${id}/${active}`)
// 批量激活/关闭
export const batchUpdateCategoryUrlApi = (body, active) => fetchJSONByPost(`/adms_api_v0/landingLink/batch/${active}`, body)
// 更新
export const updateCategoryUrlDetailApi = (id, body) => fetchJSONByPost(`/adms_api_v0/landingLink/${id}`, body)
// 获取某一
export const getCategoryUrlApi = (id) => fetchJSONByGet(`/adms_api_v0/landingLink/${id}`)

// Country & Language
// 创建
export const createCountryLanguageApi = (body) => fetchJSONByPost('/adms_api_v0/language', body)
// 获取列表
export const fetchCountryLanguagesApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/language/page', params, {trigger})
// 激活/关闭
export const updateCountryLanguageApi = (id, active) => fetchJSONByPost(`/adms_api_v0/language/${id}/${active}`)
// 批量激活/关闭
export const batchUpdateCountryLanguageApi = (body, active) => fetchJSONByPost(`/adms_api_v0/language/batch/${active}`, body)
// 更新
export const updateCountryLanguageDetailApi = (id, body) => fetchJSONByPost(`/adms_api_v0/language/${id}`, body)
// 获取某一
export const getCountryLanguageApi = (id) => fetchJSONByGet(`/adms_api_v0/language/${id}`)

// 获取品类
export const getCategoriesApi = () => fetchJSONByGet('/adms_api_v0/categories')

// Audience
// 创建
export const createAudienceApi = (body) => fetchJSONByPost('/adms_api_v0/facebook/audience', body)
// 获取列表
export const fetchAudiencesApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/facebook/audience/page', params, {trigger})
// 激活/关闭
export const activeAudiencesApi = (ids) => fetchJSONByPost(`/adms_api_v0/facebook/audience/active`, ids)
// 批量激活/关闭
export const inactiveAudiencesApi = (ids) => fetchJSONByPost(`/adms_api_v0/facebook/audience/inactive`, ids)
// 更新
export const updateAudienceDetailApi = (id, body) => fetchJSONByPost(`/adms_api_v0/facebook/audience/${id}`, body)
// 获取某一
export const getAudienceApi = (id) => fetchJSONByGet(`/adms_api_v0/facebook/audience/${id}`)
// 查询建议的interest
export const fetchInterestsApi = (website, keyword) => fetchJSONByGet(`/adms_api_v0/facebook/${website}/interest`, {keyword})
// 获取custom audiences列表
export const fetchCustomAudiencesApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/facebook/customAudience/page', params, {trigger})
// 获取所有pixel
export const fetchAllPixelsApi = (website) => fetchJSONByGet(`/adms_api_v0/facebook/${website}/pixels`)
// 获取所有catalog
export const fetchAllCatalogsApi = (website) => fetchJSONByGet(`/adms_api_v0/facebook/${website}/catalogs`)
// 创建custom audience
export const newCustomAduienceApi = (body) => fetchJSONByPost('/adms_api_v0/facebook/customAudience', body)
// 获取lookalike audiences列表
export const fetchLookAlikeAudiencesApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/facebook/lookAlikeAudience/page', params, {trigger})
// 创建lookalike audience
export const newLookAlikeAudienceApi = (body) => fetchJSONByPost('/adms_api_v0/facebook/lookAlikeAudience', body)
// 获取所有page
export const fetchAllPagesApi = (website) => fetchJSONByGet(`/adms_api_v0/facebook/${website}/pages`)
// 获取所有audiences
export const fetchAllAudiencesApi = (website) => fetchJSONByGet(`/adms_api_v0/facebook/${website}/audiences`)

// 站点&国家
export const getSiteCountriesApi = (website) => fetchJSONByGet(`/adms_api_v0/countries/${website}`, {original:false})
export const getSiteAllCountriesApi = (website) => fetchJSONByGet(`/adms_api_v0/countries/${website}`)
export const newCountriesApi = (body) => fetchJSONByPost('/adms_api_v0/countries/new', body)
export const renewCountriesApi = (body) => fetchJSONByPost('/adms_api_v0/countries/update', body)