import {fetchJSONByGet, fetchJSONByPost, fetchJSONByDel} from './index.js'
// 获取feed列表
export const fetchFeedQueuesApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/feed/task/page', params, {trigger})
// 创建feed任务
export const createFeedApi = (body) => fetchJSONByPost('/adms_api_v0/feed/task', body)
// 中止任务
export const stopFeedApi = (id) => fetchJSONByPost(`/adms_api_v0/feed/stop/${id}`)
// 批量中止
export const batchStopFeedsApi = (body) => fetchJSONByPost('/adms_api_v0/feed/stop/batch', body)
// 下载任务
export const downloadFeedApi = (id) => fetchJSONByPost(`/adms_api_v0/feed/download/${id}`, {}, {responseType: 'blob'})
// 获取所有分类
export const fetchGoogleProductCategoriesApi = (params) => fetchJSONByGet('/adms_api_v0/feed/category', params)
// 获取Feed内容配置 根据商品库
export const fetchFeedContentsApi = (params, trigger = false) => fetchJSONByGet(`/adms_api_v0/feed/config/page`, params, {trigger})
// 获取日志
export const fetchFeedContentLogsApi = (params) => fetchJSONByGet(`/adms_api_v0/feed/config/operation/page`, params)
// 内容配置
export const updateFeedContentApi = (body) => fetchJSONByPost('/adms_api_v0/feed/config', body)
// 获取Feed Channels
export const fetchChannelsApi = () => fetchJSONByGet('/adms_api_v0/feed/channels')
// 根据站点和渠道获取生效的feeds
export const fetchActiveFeedsApi = (params) => fetchJSONByGet('/adms_api_v0/feed/activeFeeds', params)
// 配置tag内容
export const newTagApi = (body) => fetchJSONByPost('/adms_api_v0/feed/tag', body)
// 配置tag内容
export const updateTagApi = (id, body) => fetchJSONByPost(`/adms_api_v0/feed/tag/${id}`, body)
// 删除tag配置
export const delTagApi = (id) => fetchJSONByDel(`/adms_api_v0/feed/tag/${id}`)
// 分页查询tag配置
export const fetchTagsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/feed/tag/page', params, {trigger})

export const manageTagApi = (id, action) => fetchJSONByPost(`/adms_api_v0/feed/tag/${id}/managent`, null, {params: {action}})
export const fetchTagLogsApi = (id) => fetchJSONByPost(`/adms_api_v0/feed/tag/${id}/log`)
