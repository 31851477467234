import {fetchJSONByGet, fetchJSONByPost, fetchJSONByDel} from './index.js'
// 获取用户列表
export const fetchUsersApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/user/page', params, {trigger})
// 创建
export const createUserApi = (body) => fetchJSONByPost('/adms_api_v0/user', body)
// 更新
export const updateUserApi = (id, body) => fetchJSONByPost(`/adms_api_v0/user/${id}`, body)
// 获取用户信息
export const getUserApi = (id) => fetchJSONByGet(`/adms_api_v0/user/${id}`)
// 登录
export const loginApi = (body) => fetchJSONByPost('/adms_api_v0/login', body)
// 重置密码
export const resetPwdApi = (id, body) => fetchJSONByPost(`/adms_api_v0/user/${id}/password/reset`, body)
// 删除用户
export const deleteUserApi = (id) => fetchJSONByDel(`/adms_api_v0/user/${id}`)