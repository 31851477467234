import {fetchJSONByGet, fetchJSONByPost} from './index.js'
// 创建权限
export const newItemApi = (body) => fetchJSONByPost('/adms_api_v0/account/token', body)
// 获取权限列表
export const getListApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/account/token/page', params, {trigger})
// 激活/关闭权限
export const activeItemApi = (id, active) => fetchJSONByPost(`/adms_api_v0/account/token/${id}/${active}`)
// 批量激活/关闭权限
export const activeItemsApi = (body, active) => fetchJSONByPost(`/adms_api_v0/account/token/batch/${active}`, body)
// 更新
export const updateItemApi = (id, body) => fetchJSONByPost(`/adms_api_v0/account/token/${id}`, body)