import {fetchJSONByGet, fetchJSONByPost} from './index.js'

// GG Display
// 未去重选款
export const fetchProductsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/google/ads_post/page', params, {trigger})
// 去重选款
export const fetchDistinctProductsApi = (params, trigger) => fetchJSONByGet('/adms_api_v0/google/ads_post/page/distinct', params, {trigger})
// 重复选款列表
export const fetchBlockProductsApi = (params) => fetchJSONByGet('/adms_api_v0/google/ads_post/block/page', params)
// 解禁重复选款
export const delBlockProductsApi = (body) => fetchJSONByPost('/adms_api_v0/google/ads_post/block/remove', body)
// 查看广告图组
export const fetchAdImgGroupsApi = (params, trigger = false) => fetchJSONByGet('/adms_api_v0/google/ads_post/single/page', params, {trigger})
// 预览
export const previewAdsApi = (body, single = false) => fetchJSONByPost('/adms_api_v0/google/ads_post/single/preview', body)
// 发布广告
export const publishAdsApi = (id) => fetchJSONByPost(`/adms_api_v0/google/ads_post/confirm/${id}`)
// 生成广告图
export const createAdImgApi = (body) => fetchJSONByPost('/adms_api_v0/google/ads_post/convertCarouselToSingle', body)